import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "transaction-status-receipts-statements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#transaction-status-receipts-statements",
        "aria-label": "transaction status receipts statements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Transaction status, receipts, statements`}</h1>
    <p>{`When a claim is approved by Comcare before 5pm Australian Eastern Time, it will be settled to the provider’s account the next business day. If the claim is approved after 5pm, it will be included in the payment run for the next business day, and the payment will be received one business day later. `}<br /></p>
    <p>{`Remittance reports are available on the `}<a parentName="p" {...{
        "href": "https://connect.medipass.io/auth?forwardTo=/"
      }}>{`Connect Portal`}</a>{` when the payment has been made. Remittance reports will include all payments to a provider for the given settlement period, which can include payments for other funding sources such as icare, Comcare and payment cards. `}<br /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      